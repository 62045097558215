h1 {
    font-size: 1.65em;
}

h2 {
    font-size: 1.5em;
}

h3 {
    font-size: 1.35em;
}

h4 {
    font-size: 1.25em;
}

h5 {
    font-size: 1.08em;
}

h6 {
    font-size: 1em;
}

.Syntax-table .output code {
    background-color: #f3f8fd;
}

.Syntax-table .MuiTableCell-head {
    color: #495057!important;
    font-size: 1.14em!important;
    font-weight: 700!important;
    background-color: #e9ecef;
}
